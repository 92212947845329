
import { EnumTaskManagerRadioButtons } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  name: 'TaskManagerConfigTaskDayOfWeek',
  props: {
    cronDaysOfWeek: {
      type: String as () => string,
      required: true,
    },
    daysOfWeekOptions: {
      type: Array as () => object[],
      required: true,
    },
    daysOfWeekSelectedIsValid: {
      type: Boolean as () => boolean,
      required: true,
    },
    isFormValid: {
      type: Boolean as () => boolean,
      required: true,
    },
  },
  data() {
    return {
      EnumTaskManagerRadioButtons,
      radioButtonWeek: EnumTaskManagerRadioButtons.EveryDaysOfWeek,
      daysOfWeekSelected: [] as string[],
    };
  },
  watch: {
    daysOfWeekSelected(_newValue: string, oldValue: string): void {
      let cronDaysOfWeek = '*';

      if (
        this.daysOfWeekSelected.length < 7
        && this.daysOfWeekSelected.length > 0
      ) {
        const days = this.daysOfWeekSelected.slice();
        cronDaysOfWeek = days.sort().join(',');
      }

      this.$emit('update:cronDaysOfWeek', cronDaysOfWeek);

      const daysOfWeekSelectedIsValid = this.daysOfWeekSelected.length > 0
        || oldValue.length > 0
        || this.radioButtonWeek !== EnumTaskManagerRadioButtons.SelectDaysOfWeek;
      this.$emit('update:daysOfWeekSelectedIsValid', daysOfWeekSelectedIsValid);
    },
    cronDaysOfWeek(): void {
      const dayWeek = this.cronDaysOfWeek;
      if (dayWeek === '*') {
        this.radioButtonWeek = EnumTaskManagerRadioButtons.EveryDaysOfWeek;
      } else {
        this.daysOfWeekSelected = dayWeek.split(',');
        this.radioButtonWeek = EnumTaskManagerRadioButtons.SelectDaysOfWeek;
      }
    },
  },
  methods: {
    setEveryDaysOfWeek(): void {
      this.daysOfWeekSelected = [];
      this.$emit('update:cronDaysOfWeek', '*');
      this.radioButtonWeek = EnumTaskManagerRadioButtons.EveryDaysOfWeek;
    },
  },
});
