
import Vue from 'vue';
import { EnumTaskManagerRadioButtons } from '@/types';

export default Vue.extend({
  name: 'TaskManagerConfigTaskHourMinute',
  props: {
    cronHour: {
      type: String as () => string,
      required: true,
    },
    cronMinute: {
      type: String as () => string,
      required: true,
    },
  },
  data() {
    return {
      EnumTaskManagerRadioButtons,
      hour: null as null | number,
      hourFrom: null as null | number,
      hourTo: null as null | number,
      minute: null as null | number,
      everyXMinutes: null as null | number,
      everyXHours: null as null | number,
      specificHour: null as null | Date,
      radioButtonHour: EnumTaskManagerRadioButtons.EveryHours,
    };
  },
  watch: {
    specificHour(): void {
      const validHourRegex = new RegExp('^([0-1][0-9]|2[0-3]):([0-5][0-9])$');
      const hour = this.getStringHourMinute(this.specificHour as Date);

      if (validHourRegex.test(hour)) {
        const [cronHour, cronMinute] = hour.split(':');
        this.$emit('update:cronHour', cronHour);
        this.$emit('update:cronMinute', cronMinute);
      }
    },
    hour(): void {
      const cronHour = this.hour ? this.hour.toString() : '*';
      this.$emit('update:cronHour', cronHour);
    },
    minute(): void {
      const cronMinute = this.minute ? this.minute.toString() : '0';
      this.$emit('update:cronMinute', cronMinute);
    },
    hourTo(): void {
      if (this.hourFrom && this.hourTo) {
        const cronHour = `${this.hourFrom}-${this.hourTo}`;
        this.$emit('update:cronHour', cronHour);
      }
    },
    hourFrom(): void {
      if (this.hourFrom && this.hourTo) {
        const cronHour = `${this.hourFrom}-${this.hourTo}`;
        this.$emit('update:cronHour', cronHour);
      }
    },
    everyXMinutes(): void {
      const cronMinute = this.everyXMinutes ? `*/${this.everyXMinutes}` : '0';
      this.$emit('update:cronMinute', cronMinute);
    },
    everyXHours(): void {
      const cronHour = this.everyXHours ? `*/${this.everyXHours}` : '*';
      this.$emit('update:cronHour', cronHour);
    },
  },
  mounted() {
    this.updateCronMinuteAndHour();
  },
  methods: {
    setEveryHour(): void {
      this.resetHourAndMinute();
    },
    setSpecificHour(): void {
      this.resetHourAndMinute();
      this.radioButtonHour = EnumTaskManagerRadioButtons.SpecificHour;
    },
    setEveryXMinutes(): void {
      this.resetHourAndMinute();
      this.radioButtonHour = EnumTaskManagerRadioButtons.EveryXMinutes;
    },
    setEveryXHours(): void {
      this.resetHourAndMinute();
      this.radioButtonHour = EnumTaskManagerRadioButtons.EveryXHours;
    },
    setInterval(): void {
      this.resetHourAndMinute();
      this.radioButtonHour = EnumTaskManagerRadioButtons.Interval;
    },
    updateCronMinuteAndHour(): void {
      const hour = this.cronHour;
      const minute = this.cronMinute;
      if (hour.includes('/')) {
        this.everyXHours = +hour.split('/')[1];
        this.radioButtonHour = EnumTaskManagerRadioButtons.EveryXHours;
      } else if (hour.includes('-')) {
        this.hourFrom = +hour.split('-')[0];
        this.hourTo = +hour.split('-')[1];
        this.everyXMinutes = +minute.split('/')[1];
        this.radioButtonHour = EnumTaskManagerRadioButtons.Interval;
      } else if (hour === '*') {
        this.hour = null;
        this.radioButtonHour = EnumTaskManagerRadioButtons.EveryHours;
      } else {
        this.specificHour = this.$moment().set('hours', +hour).set('minutes', +minute).toDate();
        this.radioButtonHour = EnumTaskManagerRadioButtons.SpecificHour;
      }
    },
    resetHourAndMinute() {
      this.$emit('update:specificHour', '');
      this.$emit('update:cronHour', '*');
      this.$emit('update:cronMinute', '0');

      this.hour = null;
      this.hourFrom = null;
      this.hourTo = null;
      this.minute = null;
      this.everyXMinutes = null;
      this.everyXHours = null;
      this.specificHour = null;
    },
    getStringHourMinute(hour: Date): string {
      return (
        `${hour.getHours()}:${
          hour.getMinutes().toString().length === 2
            ? hour.getMinutes()
            : `0${hour.getMinutes().toString()}`
        }`
          .padStart(5, '0')
      );
    },
  },
});
