var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      style: { maxWidth: "850px", minWidth: "850px" },
      attrs: {
        visible: _vm.displayModal,
        "content-style": {
          padding: "0",
          margin: "18px 12px 24px 1.125rem",
          paddingBottom: "24px",
        },
        header: _vm.header,
        modal: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.displayModal = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("Button", {
                staticClass: "outlined p-button-raised",
                attrs: { label: _vm.$t("cancel") },
                on: {
                  click: function ($event) {
                    _vm.displayModal = false
                  },
                },
              }),
              _c("Button", {
                staticClass: "p-button-raised",
                attrs: { label: _vm.$t("save") },
                on: { click: _vm.createOrUpdateTaskScheduler },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("TaskManagerConfigTask", {
        ref: "taskManagerConfigTask",
        attrs: { "task-scheduler": _vm.taskScheduler },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }