var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("p", { staticClass: "my-3 subtitle" }, [
      _vm._v(" " + _vm._s(_vm.$t("taskManager.configTask.subtitleHour")) + " "),
    ]),
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "flex flex-col gap-5 w-60" }, [
        _c(
          "div",
          { staticClass: "h-10 flex items-center gap-2 mr-4" },
          [
            _c("RadioButton", {
              attrs: { id: "everyHours", value: "everyHours" },
              on: { change: _vm.setEveryHour },
              model: {
                value: _vm.radioButtonHour,
                callback: function ($$v) {
                  _vm.radioButtonHour = $$v
                },
                expression: "radioButtonHour",
              },
            }),
            _c(
              "label",
              { staticClass: "text-base", attrs: { for: "everyHours" } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("taskManager.configTask.everyHours")) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "h-10 flex items-center gap-2 mr-4" },
          [
            _c("RadioButton", {
              attrs: { id: "specificHour", value: "specificHour" },
              on: { change: _vm.setSpecificHour },
              model: {
                value: _vm.radioButtonHour,
                callback: function ($$v) {
                  _vm.radioButtonHour = $$v
                },
                expression: "radioButtonHour",
              },
            }),
            _c(
              "label",
              { staticClass: "text-base", attrs: { for: "specificHour" } },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("taskManager.configTask.specificHour")) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "h-10 flex items-center gap-2 mr-4" },
          [
            _c("RadioButton", {
              attrs: { id: "interval", value: "interval" },
              on: { change: _vm.setInterval },
              model: {
                value: _vm.radioButtonHour,
                callback: function ($$v) {
                  _vm.radioButtonHour = $$v
                },
                expression: "radioButtonHour",
              },
            }),
            _c(
              "label",
              { staticClass: "text-base", attrs: { for: "interval" } },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("taskManager.configTask.interval")) + " "
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "h-10 flex items-center gap-2 mr-4" },
          [
            _c("RadioButton", {
              attrs: { id: "everyXHours", value: "everyXHours" },
              on: { change: _vm.setEveryXHours },
              model: {
                value: _vm.radioButtonHour,
                callback: function ($$v) {
                  _vm.radioButtonHour = $$v
                },
                expression: "radioButtonHour",
              },
            }),
            _c(
              "label",
              { staticClass: "text-base", attrs: { for: "everyXHours" } },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("taskManager.configTask.everyXHours"))
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-col gap-5" },
        [
          _c("div", { staticClass: "h-10" }),
          _c("input-validation", {
            staticClass: "whitespace-nowrap relative",
            attrs: {
              rules: `${
                _vm.radioButtonHour ===
                _vm.EnumTaskManagerRadioButtons.SpecificHour
                  ? "required"
                  : ""
              }`,
              slim: true,
              mode: "lazy",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      [
                        _c("Calendar", {
                          staticClass: "specificHour",
                          class: { "p-invalid": errors[0] },
                          attrs: {
                            disabled:
                              _vm.radioButtonHour !==
                              _vm.EnumTaskManagerRadioButtons.SpecificHour,
                            placeholder: "12:12",
                            "time-only": true,
                            "show-time": true,
                            "manual-input": false,
                          },
                          model: {
                            value: _vm.specificHour,
                            callback: function ($$v) {
                              _vm.specificHour = $$v
                            },
                            expression: "specificHour",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "small",
                      { staticClass: "absolute -bottom-5 p-invalid" },
                      [_vm._v(" " + _vm._s(errors[0]) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c("input-validation", {
                staticClass: "whitespace-nowrap relative",
                attrs: {
                  rules: `${
                    _vm.radioButtonHour ===
                    _vm.EnumTaskManagerRadioButtons.Interval
                      ? "required|min_value:0|max_value:22"
                      : ""
                  }`,
                  slim: true,
                  name: _vm.$t("taskManager.configTask.from"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center mr-2 interval-label",
                          },
                          [
                            _c("label", { staticClass: "px-3" }, [
                              _vm._v(
                                _vm._s(_vm.$t("taskManager.configTask.from")) +
                                  " "
                              ),
                            ]),
                            _c("InputNumber", {
                              staticClass: "input-number interval",
                              attrs: {
                                placeholder: "--",
                                "show-buttons": "",
                                max: 22,
                                min: 0,
                                disabled:
                                  _vm.radioButtonHour !==
                                  _vm.EnumTaskManagerRadioButtons.Interval,
                              },
                              model: {
                                value: _vm.hourFrom,
                                callback: function ($$v) {
                                  _vm.hourFrom = $$v
                                },
                                expression: "hourFrom",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "small",
                          { staticClass: "absolute -bottom-5 p-invalid" },
                          [_vm._v(" " + _vm._s(errors[0]) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("input-validation", {
                staticClass: "whitespace-nowrap relative",
                attrs: {
                  rules: `${
                    _vm.radioButtonHour ===
                    _vm.EnumTaskManagerRadioButtons.Interval
                      ? "required|min_value:0|max_value:23"
                      : ""
                  }`,
                  slim: true,
                  name: _vm.$t("taskManager.configTask.to"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center mr-2 interval-label",
                          },
                          [
                            _c("label", { staticClass: "px-3" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("taskManager.configTask.to")) +
                                  " "
                              ),
                            ]),
                            _c("InputNumber", {
                              staticClass: "input-number interval",
                              attrs: {
                                placeholder: "--",
                                "show-buttons": "",
                                max: 23,
                                min: 0,
                                disabled:
                                  _vm.radioButtonHour !==
                                  _vm.EnumTaskManagerRadioButtons.Interval,
                              },
                              model: {
                                value: _vm.hourTo,
                                callback: function ($$v) {
                                  _vm.hourTo = $$v
                                },
                                expression: "hourTo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "small",
                          { staticClass: "absolute -bottom-5 p-invalid" },
                          [_vm._v(" " + _vm._s(errors[0]) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("input-validation", {
                staticClass: "whitespace-nowrap relative",
                attrs: {
                  rules: `${
                    _vm.radioButtonHour ===
                    _vm.EnumTaskManagerRadioButtons.Interval
                      ? "required|min_value:0|max_value:59"
                      : ""
                  }`,
                  slim: true,
                  name: _vm.$t("taskManager.configTask.everyXMinutes"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "flex items-center interval-label" },
                          [
                            _c("label", { staticClass: "px-3" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "taskManager.configTask.everyXMinutes"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("InputNumber", {
                              staticClass: "input-number interval",
                              attrs: {
                                placeholder: "--",
                                "show-buttons": "",
                                min: 0,
                                max: 59,
                                disabled:
                                  _vm.radioButtonHour !==
                                  _vm.EnumTaskManagerRadioButtons.Interval,
                              },
                              model: {
                                value: _vm.everyXMinutes,
                                callback: function ($$v) {
                                  _vm.everyXMinutes = $$v
                                },
                                expression: "everyXMinutes",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "small",
                          { staticClass: "absolute -bottom-5 p-invalid" },
                          [_vm._v(" " + _vm._s(errors[0]) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex gap-4" },
            [
              _c("input-validation", {
                staticClass: "whitespace-nowrap relative",
                attrs: {
                  rules: `${
                    _vm.radioButtonHour ===
                    _vm.EnumTaskManagerRadioButtons.EveryXHours
                      ? "required"
                      : ""
                  }`,
                  slim: true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "div",
                          [
                            _c("InputNumber", {
                              staticClass: "input-number",
                              attrs: {
                                placeholder: "--",
                                "show-buttons": "",
                                min: 0,
                                max: 23,
                                disabled:
                                  _vm.radioButtonHour !==
                                  _vm.EnumTaskManagerRadioButtons.EveryXHours,
                              },
                              model: {
                                value: _vm.everyXHours,
                                callback: function ($$v) {
                                  _vm.everyXHours = $$v
                                },
                                expression: "everyXHours",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "small",
                          { staticClass: "absolute -bottom-5 p-invalid" },
                          [_vm._v(" " + _vm._s(errors[0]) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("input-validation", {
                staticClass: "whitespace-nowrap relative",
                attrs: {
                  rules:
                    _vm.radioButtonHour ===
                    _vm.EnumTaskManagerRadioButtons.EveryXHours
                      ? { regex: /^[5-9]$|^0$|^[1-5][0-9]$/ }
                      : "",
                  slim: true,
                  name: _vm.$t("taskManager.configTask.everyXMinutes"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ errors }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "flex items-center interval-label" },
                          [
                            _c("label", { staticClass: "px-3" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "taskManager.configTask.everyXMinutes"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("InputNumber", {
                              staticClass: "input-number interval",
                              attrs: {
                                placeholder: "--",
                                "show-buttons": "",
                                min: 0,
                                max: 59,
                                disabled:
                                  _vm.radioButtonHour !==
                                  _vm.EnumTaskManagerRadioButtons.EveryXHours,
                              },
                              model: {
                                value: _vm.everyXMinutes,
                                callback: function ($$v) {
                                  _vm.everyXMinutes = $$v
                                },
                                expression: "everyXMinutes",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "small",
                          { staticClass: "absolute -bottom-5 p-invalid" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  errors[0]
                                    ? "O campo deve ser igual a 0 ou entre 5 e 59"
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }