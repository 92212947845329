
import { EnumTaskManagerRadioButtons } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  name: 'TaskManagerConfigTaskMonth',
  props: {
    monthsOptions: {
      type: Array as () => object[],
      required: true,
    },
    monthsSelectedIsValid: {
      type: Boolean as () => boolean,
      required: true,
    },
    isFormValid: {
      type: Boolean as () => boolean,
      required: true,
    },
    cronMonths: {
      type: String as () => string,
      required: true,
    },
  },
  data() {
    return {
      EnumTaskManagerRadioButtons,
      radioButtonMonth: EnumTaskManagerRadioButtons.EveryMonths,
      monthsSelected: [] as string[],
      everyXMonths: null as null | number,
    };
  },
  watch: {
    monthsSelected(_newValue: string, oldValue: string): void {
      let cronMonths = '*';
      if (this.monthsSelected.length < 12 && this.monthsSelected.length > 0) {
        const months = this.monthsSelected.filter(
          (month) => month !== '*' && month !== '',
        );
        cronMonths = months.sort().join(',');
      }
      this.$emit('update:cronMonths', cronMonths);

      const monthsSelectedIsValid = this.monthsSelected.length > 0
        || oldValue.length > 0
        || this.radioButtonMonth !== EnumTaskManagerRadioButtons.SelectMonths;
      this.$emit('update:monthsSelectedIsValid', monthsSelectedIsValid);
    },

    everyXMonths(): void {
      if (this.everyXMonths !== null) {
        const cronMonths = this.everyXMonths ? `*/${this.everyXMonths}` : '*';
        this.$emit('update:cronMonths', cronMonths);
      }
    },
  },
  async mounted() {
    const month = this.cronMonths;

    if (month === '*') {
      this.radioButtonMonth = EnumTaskManagerRadioButtons.EveryMonths;
    } else if (month.includes('/')) {
      this.everyXMonths = +month.split('/')[1];
      this.radioButtonMonth = EnumTaskManagerRadioButtons.EveryXMounths;
    } else {
      this.monthsSelected = month.split(',');
      this.radioButtonMonth = EnumTaskManagerRadioButtons.SelectMonths;
    }
  },
  methods: {
    setEveryMonths(): void {
      this.monthsSelected = [];
      this.radioButtonMonth = EnumTaskManagerRadioButtons.EveryMonths;
      this.$emit('update:cronMonths', '*');
    },
    handleSelectMonths(): void {
      this.everyXMonths = null;
      this.$emit('update:cronMonths', '*');
    },
    handleEveryXMonths(): void {
      this.monthsSelected = [];
      this.radioButtonMonth = EnumTaskManagerRadioButtons.EveryXMounths;
      this.$emit('update:cronMonths', '*');
    },
  },
});
