
import TaskSchedulerService from '@/common/services/TaskSchedulerService';
import cronToTextMixin from '@/mixins/CronToTextMixin';
import mixins from 'vue-typed-mixins';
import TaskManagerConfigTaskHourMinute from '@/resources/task-manager/components/TaskManagerConfigTaskHourMinute.vue';
import TaskManagerConfigTaskDayOfWeek from '@/resources/task-manager/components/TaskManagerConfigTaskDayOfWeek.vue';
import TaskManagerConfigTaskDayOfMonth from '@/resources/task-manager/components/TaskManagerConfigTaskDayOfMonth.vue';
import TaskManagerConfigTaskMonth from '@/resources/task-manager/components/TaskManagerConfigTaskMonth.vue';
import {
  EnumTaskManagerRadioButtons,
  TaskRequest,
  TaskScheduler,
  TaskSchedulerPayloadMetadata,
  VForm,
} from '@/types';
import { mapMutations } from 'vuex';

export default mixins(cronToTextMixin).extend({
  name: 'TaskManagerConfigTask',
  props: {
    taskScheduler: {
      type: Object as () => TaskScheduler,
      default: null,
    },
  },
  data() {
    return {
      selectdService: '',
      monthsSelected: [] as string[],
      taskRequests: [] as TaskRequest[],
      EnumTaskManagerRadioButtons,
      cronMinute: this.taskScheduler?.config.minute || '0',
      cronHour: this.taskScheduler?.config.hour || '*',
      cronDaysOfMonth: this.taskScheduler?.config.dayMonth || '*',
      cronMonths: this.taskScheduler?.config.month || '*',
      cronDaysOfWeek: '*',
      isFormValid: true,
      daysOfWeekSelectedIsValid: true,
      monthsSelectedIsValid: true,
    };
  },
  components: {
    TaskManagerConfigTaskHourMinute,
    TaskManagerConfigTaskDayOfWeek,
    TaskManagerConfigTaskDayOfMonth,
    TaskManagerConfigTaskMonth,
  },
  computed: {
    cron(): string {
      return `${this.cronMinute} ${this.cronHour} ${this.cronDaysOfMonth} ${this.cronMonths} ${this.cronDaysOfWeek}`;
    },
    cronText(): string {
      const cronText = [];

      cronText.push(this.minuteAndHourToText(this.cronMinute, this.cronHour));
      cronText.push(this.dayOfMonthToText(this.cronDaysOfMonth));
      cronText.push(this.monthsToText(this.cronMonths));
      cronText.push(this.daysOfWeekToText(this.cronDaysOfWeek));
      const text = cronText.filter((value) => value !== '').join(', ');

      return this.capitalize(text);
    },
  },
  async created() {
    this.handleLoading(true);
    this.taskRequests = await TaskSchedulerService.getAllTaskRequests();
    if (this.taskScheduler?.id) {
      this.selectdService = this.taskScheduler.service;
      const {
        hour, minute, dayWeek, dayMonth,
      } = this.taskScheduler.config;

      this.cronHour = hour;
      this.cronMinute = minute;
      this.cronDaysOfWeek = dayWeek;
      this.cronDaysOfMonth = dayMonth;
    }
    this.handleLoading(false);
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async createOrUpdateTaskScheduler() {
      this.isFormValid = await this.validForm();
      const taskSchedulerPayloadMetadata: TaskSchedulerPayloadMetadata = {
        isValid: this.isFormValid,
        service: this.selectdService,
        masterId: this.$store.state.auth.userCompany.masterId,
        config: {
          minute: this.cronMinute,
          hour: this.cronHour,
          dayMonth: this.cronDaysOfMonth,
          month: this.cronMonths,
          dayWeek: this.cronDaysOfWeek,
          cron: this.cron,
        },
      };

      return taskSchedulerPayloadMetadata;
    },
    async validForm(): Promise<boolean> {
      return (
        (await (this.$refs.form as VForm).validate())
        && this.daysOfWeekSelectedIsValid
        && this.monthsSelectedIsValid
      );
    },
    capitalize(text: string): string {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
});
