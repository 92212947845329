
import mixins from 'vue-typed-mixins';
import TaskManagerConfigTask from '@/resources/task-manager/components/TaskManagerConfigTask.vue';
import TaskSchedulerService from '@/common/services/TaskSchedulerService';
import { TaskScheduler } from '@/types';
import { mapMutations } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';

type TaskManagerConfigTaskRef = InstanceType<typeof TaskManagerConfigTask>;

export default mixins(NotificationMixin).extend({
  name: 'TaskManagerConfigTaskModal',
  components: {
    TaskManagerConfigTask,
  },
  props: {
    showModal: {
      type: Boolean as () => boolean,
      default: false,
    },
    taskScheduler: {
      type: Object as () => TaskScheduler,
      default: null,
    },
    tasksScheduler: {
      type: Array as () => TaskScheduler[],
      required: true,
    },
  },
  data() {
    return {
      header: this.$t('taskManager.configTask.header'),
    };
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean): void {
        this.$emit('update:showModal', value);
      },
    },
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async createOrUpdateTaskScheduler(): Promise<void> {
      const taskManagerConfigTask = this.$refs
        .taskManagerConfigTask as TaskManagerConfigTaskRef;
      const { isValid, ...taskSchedulerPayload } = (
        await taskManagerConfigTask.createOrUpdateTaskScheduler()
      );
      if (isValid) {
        this.handleLoading(true);
        if (this.taskScheduler?.id) {
          try {
            const taskUpdated = await TaskSchedulerService.update(
              taskSchedulerPayload,
              this.taskScheduler.id,
            );
            const taskUpdatedIndex = this.tasksScheduler.findIndex(
              (task) => task.id === taskUpdated.id,
            );
            this.tasksScheduler.splice(taskUpdatedIndex, 1, taskUpdated);
            this.successToast({
              text: this.$t('taskManager.toast.update') as string,
            });
          } catch {
            this.errorToast({
              text: this.$t('taskManager.toast.update') as string,
            });
          }
        } else {
          try {
            const newTask = await TaskSchedulerService.create(
              taskSchedulerPayload,
            );
            this.tasksScheduler.push(newTask);
            this.successToast({
              text: this.$t('taskManager.toast.create') as string,
            });
          } catch {
            this.errorToast({
              text: this.$t('taskManager.toast.createFail') as string,
            });
          }
        }
        this.$emit('update:showModal', false);
        this.handleLoading(false);
      }
    },
  },
});
