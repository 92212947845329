
import { EnumTaskManagerRadioButtons } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  name: 'TaskManagerConfigTaskDayOfMonth',
  props: {
    cronDaysOfMonth: {
      type: String as () => string,
      required: true,
    },
  },
  data() {
    return {
      EnumTaskManagerRadioButtons,
      radioButtonDaysOfMonth: EnumTaskManagerRadioButtons.EveryDaysOfMonth,
      everyXDaysOfMonth: null as null | number,
      daysOfMonth: null as null | string,
    };
  },
  watch: {
    daysOfMonth(): void {
      let cronDaysOfMonth = this.daysOfMonth ? this.daysOfMonth : '*';

      if (this.daysOfMonth && this.daysOfMonth.includes(',')) {
        cronDaysOfMonth = cronDaysOfMonth.split(',').sort((a, b) => Number(a) - Number(b)).join(',');
      }
      this.$emit('update:cronDaysOfMonth', cronDaysOfMonth);
    },
    everyXDaysOfMonth(): void {
      const cronDaysOfMonth = this.everyXDaysOfMonth
        ? `*/${this.everyXDaysOfMonth}`
        : '*';
      this.$emit('update:cronDaysOfMonth', cronDaysOfMonth);
    },
  },
  mounted() {
    const dayMonth = this.cronDaysOfMonth;

    if (dayMonth === '*') {
      this.radioButtonDaysOfMonth = EnumTaskManagerRadioButtons.EveryDaysOfMonth;
    } else if (dayMonth.includes(',') || dayMonth.includes('-')) {
      this.daysOfMonth = dayMonth;
      this.radioButtonDaysOfMonth = EnumTaskManagerRadioButtons.SelectDaysOfMonth;
    } else if ((dayMonth.includes('/'))) {
      this.everyXDaysOfMonth = +dayMonth.split('/')[1];
      this.radioButtonDaysOfMonth = EnumTaskManagerRadioButtons.EveryXDaysOfMonth;
    }
  },
  methods: {
    setEveryXDaysOfMonth(): void {
      this.daysOfMonth = null;
    },
    setDaysOfMonth(): void {
      this.everyXDaysOfMonth = null;
    },
    setEveryDaysOfMonth(): void {
      this.everyXDaysOfMonth = null;
      this.daysOfMonth = null;
    },
  },
});
